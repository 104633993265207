<body>
    <div class="page-content page-container" id="page-content">
        <div class="padding">
            <div class="row container d-flex justify-content-center" style="margin: 0 auto; margin-top: 7%;" >
                <div class="col-xl-12 col-md-12">
                    <div class="card user-card-full">
                        <div class="row m-l-0 m-r-0">
                            <div class="col-sm-4 bg-c-lite-green user-profile">
                                <div class="card-block text-center text-white">
                                    <div class="m-b-25"> <img src="https://img.icons8.com/bubbles/100/000000/user.png" class="img-radius" alt="User-Profile-Image"> </div>
                                    <h6 class="f-w-600">Usuario 1</h6>
                                    <p>Analista de Sistema</p> 
                                    <!-- <img style="height: 30px; width: 30px;" src="../../assets/img/icono-editarPerfil.png" alt=""> -->
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="card-block">
                                    <h6 class="m-b-20 p-b-5 b-b-default f-w-600">Información</h6>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <p class="m-b-10 f-w-600">Correo</p>
                                            <h6 class="text-muted f-w-400">usuario@gmail.com</h6>
                                        </div>
                                        <div class="col-sm-6">
                                            <p class="m-b-10 f-w-600">Celular</p>
                                            <h6 class="text-muted f-w-400">98979989898</h6>
                                        </div>
                                    </div>
                                    <h6 class="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">Datos Personales</h6>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <p class="m-b-10 f-w-600">DNI</p>
                                            <h6 class="text-muted f-w-400">74662627</h6>
                                        </div>
                                        <div class="col-sm-6">
                                            <p class="m-b-10 f-w-600">Estado</p>
                                            <h6 class="text-muted f-w-400">Activo</h6>
                                        </div>
                                    </div>
                                    <ul class="social-link list-unstyled m-t-40 m-b-10">
                                        <li><a type="button" href="www.facebook.com"  target="_blank"> <img src="../../assets/img/icono-facebook.png" alt=""></a></li>
                                        <li><a type="button" href="www.instagram.com"  target="_blank"> <img src="../../assets/img/icon-instagram.png" alt=""></a></li>
                                        <li><a type="button" href="www.twitter.com"  target="_blank"> <img src="../../assets/img/icono-twitter.png" alt=""></a></li>
                                        
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>


















