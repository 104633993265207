import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pag-nosotros',
  templateUrl: './pag-nosotros.component.html',
  styleUrls: ['./pag-nosotros.component.css']
})
export class PagNosotrosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
