<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta name="author" content="Yinka Enoch Adedokun">
  <title>Inicio Sesión</title>
</head>

<body>
  <!-- Main Content -->
  <div class="container-fluid">
    <div class="row main-content bg-success text-center">
      <div class="col-md-4 text-center company__info">
        <span class="company__logo">
          <h2><span class="fa fa-android"></span></h2>
        </span>
        <h4 class="company_title">RVM</h4>
      </div>
      <div class="col-md-8 col-xs-12 col-sm-12 login_form ">
        <div class="container-fluid">
          <div class="row" style="padding-top: 2%; padding-left: 2%;">
            <h2>Iniciar Sesión</h2>
          </div>
          <div class="row">
            <form control="" class="form-group">
              <div class="row">
                <input type="text" name="username" id="username" class="form__input" placeholder="Usuario ó e-mail">
              </div>
              <div class="row">
                <!-- <span class="fa fa-lock"></span> -->
                <input type="password" name="password" id="password" class="form__input" placeholder="Contraseña">
              </div>
              <div class="row">
                <div class="col col-12">
                  <input type="checkbox" name="remember_me" id="remember_me" class="">

                  <label for="remember_me" style="padding-left: 2%;">Recordarme</label>
                </div>

              </div>
              <div class="row">
                <div class="col col-12">
                  <input type="submit" value="Iniciar Sesión" class="btn" [routerLink]="['/main-nav']">
                </div>

              </div>
            </form>
          </div>
          <div class="row">
            <p>¿No tenés una cuenta? <a routerLink="/login">Registrate ahora!</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer -->
  <div class="container-fluid text-center footer">

  </div>
</body>