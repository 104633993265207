<mat-sidenav-container class="sidenav-container">
  <mat-sidenav   #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar class="menu" >Menú</mat-toolbar>
    <mat-nav-list class="button-menu">
      <div class="perfil">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFvu4ovwGF1rmBrNYtl93L6NB0cmsTF3BlEg&usqp=CAU" alt="" id="perfil-imagen">
        <p class="nombre-usuario">Usuario 1</p>
      </div>

      <a mat-list-item class="btn-grad" routerLink="/main-nav/perfil"><img src="../../assets/img/icono-perfil.png" alt="" class="icono"  [routerLink]="['/perfil']"> Perfil</a> 
      <a mat-list-item class="btn-grad" href="#"><img src="../../assets/img/icono-reclamo.png" alt="" class="icono"> Reclamos</a>
      <a mat-list-item class="btn-grad" href="#"><img src="../../assets/img/icono-historial.png" alt="" class="icono"> Historial</a>
      <a mat-list-item class="btn-grad" href="#"><img src="../../assets/img/icono-mapa.png" alt="" class="icono"> Mapa</a>
      <a mat-list-item class="btn-grad" href="#"><img src="../../assets/img/icono-nosotros.png" alt="" class="icono"> Nosotros</a>
      <a mat-list-item class="btn-grad" href="#"><img src="../../assets/img/icono-ayuda.png" alt="" class="icono"> Ayuda</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar id="cabecera" color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu
        </mat-icon>
      </button>
      <span id="textoCabecera">Tesis-IMVM</span>
    </mat-toolbar>
    <!-- Add Content Here -->
    <div class="container p-4">

      <router-outlet></router-outlet>
      
    </div> 
  </mat-sidenav-content>
</mat-sidenav-container>
