<body>
    <!-- Navbar-->
    <header class="header">
        <nav class="navbar navbar-expand-lg navbar-light py-3">
            <div class="container">
                <!-- Navbar Brand -->
                    <img src="../../assets/img/Grupo 1@2x.png" alt="" class="navbar-brand">
            </div>
        </nav>
    </header>


    <div class="container">
        <div class="row py-5 mt-4 align-items-center">
            <!-- For Demo Purpose -->
            <!-- <div class="col-md-5 pr-lg-5 mb-5 mb-md-0"> -->
            <!-- <img src="https://res.cloudinary.com/mhmd/image/upload/v1569543678/form_d9sh6m.svg" alt="" class="img-fluid mb-3 d-none d-md-block"> -->
            <div class="col-md-5 mb-5 mb-md-0">
                <h1 id="titulo" style=" margin-top: -30%;">Creá una cuenta <br> o <br> inicía sesión</h1>
            </div>
            <!-- </div> -->

            <!-- Registeration Form -->
            <div class="col-md-7 col-lg-6 ml-auto">
                <form action="#">
                    <div class="row">

                        <!-- First Name -->
                        <div class="input-group col-lg-6 mb-4">
                            <!-- <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-4 border-md border-right-0">
                                    <i class="fa fa-user text-muted"></i>
                                </span>
                            </div> -->
                            <input id="firstName" type="text" name="firstname" placeholder="Nombre"
                                class="form-control bg-white border-left-0 border-md">
                        </div>

                        <!-- Last Name -->
                        <div class="input-group col-lg-6 mb-4">
                            <!-- <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-4 border-md border-right-0">
                                    <i class="fa fa-user text-muted"></i>
                                </span>
                            </div> -->
                            <input id="lastName" type="text" name="lastname" placeholder="Apellido"
                                class="form-control bg-white border-left-0 border-md">
                        </div>

                        <!-- Email Address -->
                        <div class="input-group col-lg-12 mb-4">
                            <!-- <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-4 border-md border-right-0">
                                    <i class="fa fa-envelope text-muted"></i>
                                </span>
                            </div> -->
                            <input id="email" type="email" name="email" placeholder="Email"
                                class="form-control bg-white border-left-0 border-md">
                        </div>

                        <!-- Phone Number -->
                        <div class="input-group col-lg-12 mb-4">
                            <!-- <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-10 border-md border-right-0">
                                    <i class="fa fa-phone-square text-muted"></i>
                                </span>
                            </div> -->
                            <select id="countryCode" name="countryCode" style="max-width: 80px"
                                class="custom-select form-control bg-white border-left-0 border-md h-100 font-weight-bold text-muted">
                                <option value="">+54</option>
                                <option value="">+12</option>
                                <option value="">+13</option>
                                <option value="">+14</option>
                            </select>
                            <input id="phoneNumber" type="tel" name="phone" placeholder="Número de celular"
                                class="form-control bg-white border-md border-left-0 pl-3">
                        </div>.


                        <!-- Job -->


                        <!-- Password -->
                        <div class="input-group col-lg-12 mb-4">
                            <!-- <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-4 border-md border-right-0">
                                    <i class="fa fa-lock text-muted"></i>
                                </span>
                            </div> -->
                            <input id="password" type="password" name="password" placeholder="Contraseña"
                                class="form-control bg-white border-left-0 border-md">
                        </div>

                        <!-- Password Confirmation -->
                        <div class="input-group col-lg-12 mb-4">
                            <!-- <div class="input-group-prepend">
                                <span class="input-group-text bg-white px-4 border-md border-right-0">
                                    <i class="fa fa-lock text-muted"></i>
                                </span>
                            </div> -->
                            <input id="passwordConfirmation" type="text" name="passwordConfirmation"
                                placeholder="Confirmá tu contraseña" class="form-control bg-white border-left-0 border-md">
                        </div>

                        <!-- Submit Button -->
                        <div class="form-group col-lg-12 mx-auto mb-0">
                            <a href="#" class="btn btn-primary btn-block py-2">
                                <span class="font-weight-bold">Creá tu cuenta</span>
                            </a>
                        </div>

                        <!-- Divider Text -->
                        <div class="form-group col-lg-12 mx-auto d-flex align-items-center my-4">
                            <div class="border-bottom w-100 ml-5"></div>
                            <span class="px-2 small text-muted font-weight-bold text-muted">O</span>
                            <div class="border-bottom w-100 mr-5"></div>
                        </div>

                        <!-- Social Login -->
                        <div class="form-group col-lg-12 mx-auto">
                            <a href="#" class="btn btn-primary btn-block py-2 btn-facebook">
                                <i class="fa fa-facebook-f mr-2"></i>
                                <span class="font-weight-bold">Continuá con tu cuenta de Facebook</span>
                            </a>
                            <a href="#" class="btn btn-primary btn-block py-2 btn-twitter">
                                <i class="fa fa-twitter mr-2"></i>
                                <span class="font-weight-bold">Continua con tu cuenta de Twitter</span>
                            </a>
                        </div>

                        <!-- Already Registered -->
                        <div class="text-center w-100">
                            <p class="text-muted font-weight-bold">Ya estás registrado? <a href="#"
                                    routerLink="/inicio-sesion" class="text-primary ml-2">Iniciar Sesión</a></p>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>

</body>